import { Box, Button, HStack, Text, VStack } from 'native-base';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { RootRoutes } from '../../../components/RootRoutes';
import TransactionsPointsAddedPage from './TransactionsPointsAddedPage';
import TransactionsPointsClaimedPage from './TransactionsPointsClaimedPage';

const TransactionsRouter: React.FC = () => {
  return (
    <Box padding={'4'} alignItems={'center'}>
      <VStack width={'full'} maxWidth={'600px'}>
        <PointsNav />
        <RootRoutes>
          <Route
            path={'/transactions/added/*'}
            element={<TransactionsPointsAddedPage />}
          />
          <Route
            path={'/transactions/claimed/*'}
            element={<TransactionsPointsClaimedPage />}
          />
          <Route
            path={'*'}
            element={<Navigate to="/transactions/added" replace />}
          />
        </RootRoutes>
      </VStack>
    </Box>
  );
};

const PointsNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <VStack>
      <Text variant={'header'} textAlign={'center'}>
        Transaction History
      </Text>
      {currentPath === '/transactions/added' ? (
        <HStack justifyContent={'center'}>
          <Button
            onPress={() => navigate('/transactions/added')}
            borderRadius={'none'}
            flex={1}
            padding={'3'}>
            Points Added
          </Button>
          <Button
            onPress={() => navigate('/transactions/claimed')}
            borderRadius={'none'}
            flex={1}
            padding={'3'}
            variant={'subtle'}>
            Points Deducted
          </Button>
        </HStack>
      ) : (
        <HStack justifyContent={'center'}>
          <Button
            onPress={() => navigate('/transactions/added')}
            borderRadius={'none'}
            flex={1}
            padding={'3'}
            variant={'subtle'}>
            Points Added
          </Button>
          <Button
            onPress={() => navigate('/transactions/claimed')}
            borderRadius={'none'}
            flex={1}
            padding={'3'}>
            Points Deducted
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export default TransactionsRouter;
