import { useQuery } from '@tanstack/react-query';

import { Buyers } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';

/**
 * Hook to retrieve current buyer
 */
export const useBuyerUser = () => {
  return useQuery<Buyers.BuyerUser>({
    queryKey: ['/buyer_users', '/main'],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: '/buyer_users/main',
        })
      ).buyerUser;
    },
  });
};
