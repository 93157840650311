import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RootRoutes } from '../../components/RootRoutes';
import AuthLoginPage from './AuthLoginPage';

const AuthRouter = () => {
  return (
    <RootRoutes>
      <Route path={'/auth/login/*'} element={<AuthLoginPage />} />
      <Route path={'*'} element={<Navigate to={'/auth/login'} replace />} />
    </RootRoutes>
  );
};

export default AuthRouter;
