interface IConfig {
  [clientEnv: string]: {
    CLIENT_ENV: string;
    SERVER_BASE_URL: string;
    APP_BASE_DOMAIN: string;
  };
}

const EnvConfigs: IConfig = {
  prod: {
    CLIENT_ENV: 'prod',
    SERVER_BASE_URL: 'https://api.wafflepos.com',
    APP_BASE_DOMAIN: 'mywaffle.app',
  },
  staging: {
    CLIENT_ENV: 'staging',
    SERVER_BASE_URL: 'https://api-staging.wafflepos.com',
    APP_BASE_DOMAIN: 'mywafflestaging.app',
  },
  dev: {
    CLIENT_ENV: 'dev',
    SERVER_BASE_URL: 'https://api-dev.wafflepos.com',
    APP_BASE_DOMAIN: 'localhost:3000',
  },
  local: {
    CLIENT_ENV: 'local',
    SERVER_BASE_URL: 'http://localhost:8080',
    APP_BASE_DOMAIN: 'localhost:3000',
  },
} as const;

const clientEnv: string = process.env.REACT_APP_CLIENT_ENV || 'local';
const EnvConfig = EnvConfigs[clientEnv];
export default EnvConfig;
