import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BuyerUserAuthLoginResponse } from '@waffle/common/src/models/buyers';
import { debounceAsync } from '@waffle/common/src/util/async/debounceAsync';

interface WaffleApiClientConfig {
  apiType: 'sellers' | 'buyers';
  host: string;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  onAuthTokens: (args: {
    accessToken: string | undefined;
    refreshToken: string | undefined;
  }) => void;
}

export default class WaffleApiClient {
  private static readonly API_VERSION = '2021-01-20';

  private readonly host: string;
  private readonly apiType: 'sellers' | 'buyers';
  private accessToken: string | undefined;
  private refreshToken: string | undefined;
  private onAuthTokens: (args: {
    accessToken: string | undefined;
    refreshToken: string | undefined;
  }) => void;

  private readonly baseUrl: string;

  constructor({
    host,
    apiType,
    accessToken,
    refreshToken,
    onAuthTokens,
  }: WaffleApiClientConfig) {
    this.host = host;
    this.apiType = apiType;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.onAuthTokens = onAuthTokens;
    this.baseUrl = `${host}/${apiType}/${WaffleApiClient.API_VERSION}`;
  }

  /**
   * verifyOtp
   */
  public verifyOtp = async ({
    otp,
    mobileNumber,
  }: {
    otp: string;
    mobileNumber: string;
  }): Promise<void> => {
    const response: AxiosResponse = await axios({
      method: 'POST',
      url: `${this.baseUrl}/auth/otp/verify`,
      data: { otp: otp, mobileNumber: mobileNumber },
      timeout: 30000, // Timeout after 30 seconds
    }).catch((error) => {
      throw error;
    });

    const loginPayload: BuyerUserAuthLoginResponse = response.data;
    this.accessToken = loginPayload.accessToken;
    this.refreshToken = loginPayload.refreshToken;
    this.onAuthTokens({
      accessToken: loginPayload.accessToken,
      refreshToken: loginPayload.refreshToken,
    });
  };

  private refreshTokens = async (): Promise<void> => {
    try {
      // Retrieve existing refresh token
      // If the refresh token doesn't exist, we just log the user out
      const refreshToken: string | undefined = this.refreshToken;
      if (!refreshToken) {
        this.onAuthTokens({
          accessToken: undefined,
          refreshToken: undefined,
        });
        return;
      }

      // Try to refresh the tokens
      const response: AxiosResponse = await axios({
        url: `${this.baseUrl}/auth/refresh`,
        method: 'POST',
        data: {
          refreshToken: refreshToken,
        },
      });
      const newAccessToken: string = response.data.accessToken;
      const newRefreshToken: string = response.data.refreshToken;

      this.accessToken = newAccessToken;
      this.refreshToken = newRefreshToken;

      this.onAuthTokens({
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
      });
    } catch (error) {
      // If refreshing tokens failed, we just log the user out
      this.onAuthTokens({ accessToken: undefined, refreshToken: undefined });
    }
  };

  /**
   * Need to debounce here because refreshing the same token twice will cause the token to be revoked
   * So we wait at least 3 seconds before trying to refresh tokens again
   */
  private debouncedRefreshTokens = debounceAsync(this.refreshTokens, 3000);

  // TODO: split this into authenticated and unauthenticated requests
  public request = async (
    axiosRequestConfig: AxiosRequestConfig,
  ): Promise<any> => {
    // // TODO: uncomement this when we split into  authenticated and unauthenticated requests
    // if (!this.accessToken) {
    //   throw new Error('Not logged in');
    // }

    try {
      // 2. Make the request
      const config: AxiosRequestConfig = {
        baseURL: this.baseUrl,
        headers: {
          ...axiosRequestConfig.headers,
          Authorization: this.accessToken
            ? `Bearer ${this.accessToken}`
            : undefined,
          'Content-Type': 'application/json',
        },
        timeout: 30000, // Timeout after 30 seconds
        ...axiosRequestConfig,
      };
      const response: AxiosResponse = await axios(config);
      return response.data;
    } catch (error: any) {
      // 3. If error due to token expiration, we try to refresh the tokens
      if (error.response?.status === 401) {
        try {
          await this.debouncedRefreshTokens();
          return this.request(axiosRequestConfig);
        } catch (error) {
          throw error;
        }
      }
      throw error;
    }
  };
}
