import { Center, ChevronRightIcon, HStack, Spinner } from 'native-base';
import { Box, Pressable, Text } from 'native-base';
import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';

const exclamationColor = '#f9c044';

const SettingsPage = () => {
  const navigate = useNavigate();
  const { data: buyerUser } = useBuyerUser();

  if (!buyerUser) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const isProfileIncomplete: boolean =
    !buyerUser.mobileNumber ||
    !buyerUser.emailAddress ||
    !buyerUser.givenName ||
    !buyerUser.familyName ||
    !buyerUser.dateOfBirth;

  return (
    <Box alignItems={'center'}>
      <Box width={'100%'} maxWidth={'600px'} marginY={4} padding={2}>
        <Box marginBottom={4}>
          <Text variant={'header'}>Account settings</Text>
        </Box>

        <Box
          backgroundColor={'background.0'}
          padding={4}
          borderRadius={'8'}
          marginBottom={8}>
          <Box>
            <Pressable
              onPress={() => {
                navigate('/settings/profile');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'8px'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <HStack alignItems={'center'}>
                <Text variant={'label'} marginRight={1}>
                  Profile
                </Text>
                {isProfileIncomplete ? (
                  <FaExclamationCircle color={exclamationColor} />
                ) : null}
              </HStack>
              <ChevronRightIcon size={4} />
            </Pressable>

            <Pressable
              onPress={() => {
                navigate('/settings/data-privacy');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <Text variant={'label'}>Data & Privacy</Text>
              <ChevronRightIcon size={4} />
            </Pressable>
            <Pressable
              onPress={() => {
                navigate('/settings/privacy-policy');
              }}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              _hover={{ backgroundColor: 'background.50' }}
              _pressed={{ backgroundColor: 'background.100' }}>
              <Text variant={'label'}>Privacy Policy</Text>
              <ChevronRightIcon size={4} />
            </Pressable>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
