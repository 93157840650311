import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Sellers } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import EnvConfig from '../../utils/EnvConfig';

/**
 * Hook to retrieve a seller by Sellers.Seller.slug on the current subdomain
 */
export const useSubdomainSeller = () => {
  const host: string = window.location.host;
  const subdomain = useMemo(() => {
    const bottomLevelDomain: string | undefined = host.split('.')[0];
    // Hardcoded, need to change this if we ever change the host
    if (!bottomLevelDomain || bottomLevelDomain.includes('mywaffle')) {
      return undefined;
    } else {
      return bottomLevelDomain;
    }
  }, [host]);

  return useQuery<Sellers.Seller>({
    queryKey: ['/storefront/2022-10-18/business/sellers'],
    queryFn: async () => {
      const sellers: Sellers.Seller[] = (
        await buyersApiClient.request({
          method: 'GET',
          baseURL: `${EnvConfig.SERVER_BASE_URL}`,
          url: '/storefront/2022-10-26/business/sellers',
          params: {
            slug: subdomain,
            expand: [
              'sellers.logoImage',
              'sellers.latestSellerPrivacyPolicy.file',
            ],
          },
        })
      ).sellers;

      if (sellers.length !== 1) {
        throw new Error('Could not retrieve seller');
      }

      return sellers[0];
    },
  });
};
