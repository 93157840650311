import { useContext, useMemo } from 'react';
import { UNSAFE_RouteContext as RouteContext, Routes } from 'react-router-dom';

/**
 * Allows us to use absolute path names to define routes, instead of having to refactor the entire app to use relative paths.
 * https://github.com/remix-run/react-router/discussions/9841
 */
export const RootRoutes = (props) => {
  const ctx = useContext(RouteContext);

  const value = useMemo(
    () => ({
      ...ctx,
      matches: [],
    }),
    [ctx],
  );

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
};
