import { useQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import { useSubdomainSeller } from './useSubdomainSeller';

export const useRewardsProgramme = () => {
  const { data: seller } = useSubdomainSeller();

  return useQuery<Rewards.RewardsProgramme>({
    queryKey: [`/rewards_programmes`],
    queryFn: async () => {
      const rewardsProgrammes: Rewards.RewardsProgramme[] = (
        await buyersApiClient.request({
          method: 'GET',
          url: '/rewards_programmes',
          params: {
            sellerId: seller?.id,
          },
        })
      ).rewardsProgrammes;
      // Should only retrieve 1 RewardsProgramme since we are filtering by sellerId
      if (rewardsProgrammes.length !== 1) {
        throw new Error('Error fetching RewardsProgramme');
      }
      return rewardsProgrammes[0];
    },
    enabled: !!seller,
  });
};
