import { useQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import { useRewardsMembership } from './useRewardsMembership';
import { useSubdomainSeller } from './useSubdomainSeller';

export const useRewardsListings = () => {
  const { data: seller } = useSubdomainSeller();
  const { data: rewardsMembership } = useRewardsMembership();
  return useQuery<Rewards.RewardsListing[]>({
    queryKey: ['/rewards_listings', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: '/rewards_listings',
          params: { sellerId: seller?.id },
        })
      ).rewardsListings;
    },
    enabled: !!seller && !!rewardsMembership,
  });
};
