import React from 'react';

import { useAuth } from '../utils/useAuth';
import AppRouter from './app/AppRouter';
import AuthRouter from './auth/AuthRouter';

const MainRouter = () => {
  const { isAuthenticated } = useAuth();

  return <>{isAuthenticated ? <AppRouter /> : <AuthRouter />}</>;
};

export default MainRouter;
