import { Center, Spinner } from 'native-base';
import React from 'react';

const WaffleLoaderComponent = () => {
  return (
    <Center width={'100%'} height={'100%'}>
      <Spinner accessibilityLabel="Loading..." />
    </Center>
  );
};

export default WaffleLoaderComponent;
