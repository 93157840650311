import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Actionsheet,
  Box,
  Divider,
  HStack,
  HamburgerIcon,
  Image,
  Pressable,
  Spinner,
  Text,
  useBreakpointValue,
  useDisclose,
} from 'native-base';
import React from 'react';
import { FaExclamationCircle, FaSignOutAlt } from 'react-icons/fa';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { RootRoutes } from '../../components/RootRoutes';
import WaffleLoaderComponent from '../../components/WaffleLoaderComponent';
import { useBuyerUser } from '../../hooks/queries/useBuyerUser';
import { useCustomerQuery } from '../../hooks/queries/useCustomer';
import { useSubdomainSeller } from '../../hooks/queries/useSubdomainSeller';
import buyersApiClient from '../../utils/ApiService';
import { useAuth } from '../../utils/useAuth';
import PrivacyConsentPage from './privacyConsent/PrivacyConsentPage';
import RewardsPage from './rewards/RewardsPage';
import DataAndPrivacyPage from './settings/DataAndPrivacyPage';
import PrivacyPolicyPage from './settings/PrivacyPolicyPage';
import ProfilePage from './settings/ProfilePage';
import SettingsPage from './settings/SettingsPage';
import TransactionsRouter from './transactions/TransactionsRouter';

const exclamationColor = '#f9c044';

const routes = [
  {
    path: '/rewards',
    text: 'Rewards',
  },
  {
    path: '/transactions',
    text: 'Transactions History',
  },
];

const AppRouter = () => {
  const isVerticalNav: boolean = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { data: seller, isError: isSellerError } = useSubdomainSeller();
  // TODO: Change to isPending after upgrading to @tanstack/react-query v5
  const { data: customer, isPending: isCustomerPending } = useCustomerQuery();
  const { data: buyerUser } = useBuyerUser();

  if (isSellerError) {
    return <Text>Encountered an error, please refresh the page!</Text>;
  }

  if (!seller || isCustomerPending || !buyerUser) {
    return <WaffleLoaderComponent />;
  }

  if (!customer || !customer.acceptedSellerPrivacyPolicyAt) {
    return <PrivacyConsentPage />;
  }

  const isProfileIncomplete: boolean =
    !buyerUser.mobileNumber ||
    !buyerUser.emailAddress ||
    !buyerUser.givenName ||
    !buyerUser.familyName ||
    !buyerUser.dateOfBirth;

  return (
    <Box flex={1} flexDirection={isVerticalNav ? 'row' : 'column'}>
      <NavBar />
      <Box flex={1} overflowY={'scroll'}>
        <RootRoutes>
          <Route path={'/rewards/*'} element={<RewardsPage />} />
          <Route path={'/transactions/*'} element={<TransactionsRouter />} />
          <Route path={'/settings/*'} element={<SettingsPage />} />
          <Route path={'/settings/profile/*'} element={<ProfilePage />} />
          <Route
            path={'/settings/privacy-policy/*'}
            element={<PrivacyPolicyPage />}
          />
          <Route
            path={'/settings/data-privacy/*'}
            element={<DataAndPrivacyPage />}
          />

          {/* Default redirect */}
          <Route
            path={'*'}
            element={
              <>
                {!!customer.didConsentShareProfile && isProfileIncomplete ? (
                  <Navigate to={'/settings/profile'} replace />
                ) : (
                  <Navigate to={'/rewards'} replace />
                )}
              </>
            }></Route>
        </RootRoutes>
      </Box>
    </Box>
  );
};

const NavBar = () => {
  const isVerticalNav: boolean = useBreakpointValue({
    base: false,
    lg: true,
  });
  return <>{isVerticalNav ? <VerticalNavBar /> : <HorizontalNavBar />}</>;
};

const HorizontalNavBar = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshToken, setAccessToken, setRefreshToken } = useAuth();
  const { data: buyerUser, isError: buyerUserError } = useBuyerUser();
  const { isOpen, onClose, onToggle } = useDisclose();
  const {
    data: seller,
    isError: isSellerError,
    isPending: isSellerPending,
  } = useSubdomainSeller();

  const { mutate: logOut, isPending: isLogOutPending } = useMutation({
    mutationFn: async ({ refreshToken }: { refreshToken: string }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: '/auth/revoke',
        data: {
          refreshToken: refreshToken,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.removeQueries();
      setAccessToken(undefined);
      setRefreshToken(undefined);
    },
  });

  // TODO: Cleanup logic after upgrading to @tanstack/react-query v5
  if (isSellerError || buyerUserError) {
    return <Text>{`Encountered error! \nPlease contact us!`}</Text>;
  }

  if (isSellerPending) {
    return <WaffleLoaderComponent />;
  }
  if (!seller || !buyerUser) {
    return null;
  }
  const isProfileIncomplete: boolean =
    !buyerUser.mobileNumber ||
    !buyerUser.emailAddress ||
    !buyerUser.givenName ||
    !buyerUser.familyName ||
    !buyerUser.dateOfBirth;

  return (
    <>
      <HStack
        alignItems={'center'}
        top={0}
        height={'16'}
        width={'full'}
        paddingX={'4'}
        backgroundColor={'white'}
        zIndex={999}>
        <Pressable onPress={onToggle} marginRight={'4'}>
          <HamburgerIcon size="5" />
        </Pressable>
        <Box>
          <Text fontWeight={'medium'}>{seller?.name}</Text>
        </Box>
        <Box />
      </HStack>

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          {routes.map((route) => (
            <Actionsheet.Item
              key={route.path}
              padding={'4'}
              borderRadius={'lg'}
              _hover={{ backgroundColor: 'background.200' }}
              _pressed={{ backgroundColor: 'background.300' }}
              backgroundColor={
                location.pathname.includes(route.path)
                  ? 'background.100'
                  : undefined
              }
              borderWidth={location.pathname.includes(route.path) ? 1 : 0}
              borderColor={'background.400'}
              onPress={() => {
                onClose();
                navigate(route.path);
              }}>
              <Text variant={'subHeader'}>{route.text}</Text>
            </Actionsheet.Item>
          ))}
          <Divider marginY={2} />
          <Actionsheet.Item
            key={'/settings'}
            padding={'4'}
            borderRadius={'lg'}
            _hover={{ backgroundColor: 'background.200' }}
            _pressed={{ backgroundColor: 'background.300' }}
            backgroundColor={
              location.pathname.includes('/settings')
                ? 'background.100'
                : undefined
            }
            borderWidth={location.pathname.includes('/settings') ? 1 : 0}
            borderColor={'background.400'}
            onPress={() => {
              onClose();
              navigate('/settings');
            }}>
            <HStack alignItems={'center'}>
              <Text variant={'subHeader'} marginRight={2}>
                Settings
              </Text>
              {isProfileIncomplete ? (
                <FaExclamationCircle size={20} color={exclamationColor} />
              ) : null}
            </HStack>
          </Actionsheet.Item>
          <Actionsheet.Item
            padding={'4'}
            borderRadius={'lg'}
            onPress={() => {
              if (!!refreshToken) {
                logOut({ refreshToken });
              }
            }}>
            {isLogOutPending ? (
              <Spinner />
            ) : (
              <HStack alignItems={'center'}>
                <Text
                  variant={'subHeader'}
                  color={'background.500'}
                  marginRight={2}>
                  Sign Out
                </Text>
                <FaSignOutAlt color="grey" size={20} />
              </HStack>
            )}
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};
const VerticalNavBar = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: seller } = useSubdomainSeller();
  const { refreshToken, setAccessToken, setRefreshToken } = useAuth();
  const { data: buyerUser } = useBuyerUser();
  const { mutate: logOut, isPending: isLogOutPending } = useMutation({
    mutationFn: async ({ refreshToken }: { refreshToken: string }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: '/auth/revoke',
        data: {
          refreshToken: refreshToken,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.removeQueries();
      setAccessToken(undefined);
      setRefreshToken(undefined);
    },
  });

  if (!seller || !buyerUser) {
    return null;
  }
  const isProfileIncomplete: boolean =
    !buyerUser.mobileNumber ||
    !buyerUser.emailAddress ||
    !buyerUser.givenName ||
    !buyerUser.familyName ||
    !buyerUser.dateOfBirth;

  return (
    <Box>
      <Box
        left={0}
        width={'300px'}
        height={'100%'}
        backgroundColor={'background.0'}
        padding={'8'}
        justifyContent={'space-between'}>
        <Box>
          <HStack alignItems={'center'} marginBottom={4} padding={4}>
            {!!seller.logoImage && (
              <Box
                height={'40px'}
                width={'40px'}
                shadow={'5'}
                borderRadius={'md'}>
                <Image
                  resizeMode={'contain'}
                  height={'100%'}
                  width={'100%'}
                  src={seller.logoImage.thumbnailUrl}
                  alt={'Seller Logo'}
                />
              </Box>
            )}
            <Text marginLeft={2} fontWeight={'medium'} numberOfLines={1}>
              {seller.name}
            </Text>
          </HStack>

          {routes.map((route) => (
            <Pressable
              key={route.path}
              padding={'4'}
              borderRadius={'lg'}
              backgroundColor={
                location.pathname.includes(route.path)
                  ? 'background.100'
                  : undefined
              }
              _hover={{ backgroundColor: 'background.200' }}
              _pressed={{ backgroundColor: 'background.300' }}
              onPress={() => navigate(route.path)}>
              <Text variant={'subHeader'}>{route.text}</Text>
            </Pressable>
          ))}
        </Box>
        <Box>
          {/*Settings page*/}
          <Pressable
            key={'/settings'}
            padding={'4'}
            borderRadius={'lg'}
            backgroundColor={
              location.pathname.includes('/settings')
                ? 'background.100'
                : undefined
            }
            _hover={{ backgroundColor: 'background.200' }}
            _pressed={{ backgroundColor: 'background.300' }}
            onPress={() => navigate('/settings')}>
            <HStack alignItems={'center'}>
              <Text variant={'subHeader'} marginRight={1}>
                Settings
              </Text>
              {isProfileIncomplete ? (
                <FaExclamationCircle size={20} color={exclamationColor} />
              ) : null}
            </HStack>
          </Pressable>

          {/*Logout button*/}
          <Pressable
            padding={'4'}
            borderRadius={'lg'}
            _hover={{ backgroundColor: 'background.200' }}
            _pressed={{ backgroundColor: 'background.300' }}
            onPress={() => {
              if (!!refreshToken) {
                logOut({ refreshToken });
              }
            }}>
            {isLogOutPending ? (
              <Spinner />
            ) : (
              <HStack alignItems={'center'}>
                <Text
                  variant={'subHeader'}
                  color={'background.500'}
                  marginRight={2}>
                  Sign Out
                </Text>
                <FaSignOutAlt color="grey" size={20} />
              </HStack>
            )}
          </Pressable>
        </Box>
      </Box>
    </Box>
  );
};

export default AppRouter;
