import { useQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import { useSubdomainSeller } from './useSubdomainSeller';

export const useRewardsPointTransactions = () => {
  const { data: seller } = useSubdomainSeller();
  return useQuery<Rewards.PointTransaction[]>({
    queryKey: ['/rewards_point_transactions', { sellerId: seller?.id }],
    queryFn: async () => {
      const rewardsPointTransactions: Rewards.PointTransaction[] = (
        await buyersApiClient.request({
          method: 'GET',
          url: `/rewards_point_transactions`,
          params: { sellerId: seller?.id },
        })
      ).rewardsPointTransactions;

      return rewardsPointTransactions;
    },
    enabled: !!seller,
  });
};
