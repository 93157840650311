import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Center,
  HStack,
  List,
  Pressable,
  Text,
  VStack,
  useToast,
} from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { AiFillGift } from 'react-icons/ai';
import { Dimensions, ImageBackground } from 'react-native';

import { Rewards } from '@waffle/common/src/models';

import LottieEmptyIssuedRewards from '../../../assets/lottie/lf20_wrbylkbm.json';
import WaffleLoaderComponent from '../../../components/WaffleLoaderComponent';
import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';
import { useIssuedRewards } from '../../../hooks/queries/useIssuedRewards';
import { useRewardsListings } from '../../../hooks/queries/useRewardsListings';
import { useRewardsMembership } from '../../../hooks/queries/useRewardsMembership';
import { useRewardsProgramme } from '../../../hooks/queries/useRewardsProgramme';
import { useSubdomainSeller } from '../../../hooks/queries/useSubdomainSeller';
import { Colors, FontSize, Spacing } from '../../../styles/styles';
import buyersApiClient from '../../../utils/ApiService';

const RewardsPage = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data: seller, isError: isSellerError } = useSubdomainSeller();
  const { data: rewardsProgramme, isError: isRewardsProgrammeError } =
    useRewardsProgramme();
  const { data: buyerUser, isError: isBuyerUserError } = useBuyerUser();
  const { data: rewardsMembership, isError: isRewardsMembershipError } =
    useRewardsMembership();
  const { data: issuedRewards, isError: isIssuedRewardsError } =
    useIssuedRewards();
  const { data: rewardsListings, isError: isRewardsListingsError } =
    useRewardsListings();

  const currentRewardsTier: Rewards.RewardsTier | undefined = useMemo(() => {
    if (!rewardsMembership) {
      return undefined;
    }
    return rewardsMembership.rewardsTier;
  }, [rewardsMembership]);
  // Undefined if the user is at the highest tier
  const nextRewardsTier: Rewards.RewardsTier | undefined = useMemo(() => {
    if (!rewardsProgramme || !currentRewardsTier) {
      return undefined;
    }
    const currentRewardsTierIndex = rewardsProgramme.rewardsTiers.findIndex(
      (rewardsTier: Rewards.RewardsTier) =>
        rewardsTier.id === currentRewardsTier.id,
    );
    if (currentRewardsTierIndex === rewardsProgramme.rewardsTiers.length - 1) {
      return undefined; // There is no next tier because the user is at the highest tier
    }
    return rewardsProgramme.rewardsTiers[currentRewardsTierIndex + 1];
  }, [rewardsProgramme, currentRewardsTier]);

  const [isCardFlipped, setIsCardFlipped] = useState<boolean>(false);

  const [viewingOwnedOrUnownedRewards, setViewingOwnedOrUnownedRewards] =
    useState<'owned' | 'unowned'>('owned');

  // Owned Rewards List State
  const [issuedRewardsLocationsToShow, setIssuedRewardsLocationsToShow] =
    useState<boolean[]>([]);
  useEffect(() => {
    if (issuedRewards) {
      setIssuedRewardsLocationsToShow(
        issuedRewards.map((issuedReward) => false),
      );
    }
  }, [issuedRewards]);

  // Unowned Rewards List State
  const [confirmClaimIndex, setConfirmClaimIndex] = useState<
    (boolean | 'loading')[]
  >([]);
  useEffect(() => {
    if (rewardsListings) {
      setConfirmClaimIndex(rewardsListings.map((listing) => false));
    }
  }, [rewardsListings]);

  const handleClaimReward = async (
    rewardsListing: Rewards.RewardsListing,
    index: number,
  ) => {
    if (!seller || !rewardsProgramme || !rewardsListings) {
      toast.show({
        backgroundColor: Colors.ROSE_100,
        title: 'Unable to claim reward',
        placement: 'bottom',
      });
      return;
    }
    setConfirmClaimIndex([
      ...confirmClaimIndex.slice(0, index),
      'loading',
      ...confirmClaimIndex.slice(index + 1),
    ]);
    await buyersApiClient.request({
      method: 'POST',
      url: `/rewards_listings/${rewardsListing.id}/issue`,
      params: {
        sellerId: seller.id,
      },
    });
    await queryClient.invalidateQueries({ queryKey: ['/issued_rewards'] });
    await queryClient.invalidateQueries({ queryKey: ['/rewards_memberships'] });
    setConfirmClaimIndex([
      ...confirmClaimIndex.slice(0, index),
      false,
      ...confirmClaimIndex.slice(index + 1),
    ]);
    toast.show({ title: `Successfully claimed ${rewardsListing.name}!` });
  };

  if (
    isBuyerUserError ||
    isSellerError ||
    isRewardsProgrammeError ||
    isRewardsMembershipError ||
    isRewardsListingsError ||
    isIssuedRewardsError
  ) {
    return <Text>Encountered an error, please try again later!</Text>;
  }

  if (
    !seller ||
    !buyerUser ||
    !rewardsProgramme ||
    !rewardsListings ||
    !issuedRewards ||
    !currentRewardsTier
  ) {
    return <WaffleLoaderComponent />;
  }

  if (!rewardsMembership) {
    return <Text>You have not joined this rewards programme.</Text>;
  }

  return (
    <Box alignItems={'center'} padding={'4'}>
      <VStack width={'full'} maxWidth={600}>
        <Box>
          {/* 'Welcome back' header */}
          <Box>
            <Text variant={'header'}>
              {buyerUser.givenName
                ? `Hi, ${buyerUser.givenName}!`
                : 'Welcome Back!'}{' '}
              ☀️
            </Text>
          </Box>
        </Box>

        {/* Card */}
        <Center margin={8}>
          {/* Top Half */}
          <ImageBackground
            source={{
              uri: currentRewardsTier.cardBackgroundImage?.url,
            }}
            imageStyle={{ borderRadius: 16 }}
            resizeMode={'cover'}
            style={{
              borderRadius: 16,
              backgroundColor: currentRewardsTier.cardBackgroundColor,
              transform: isCardFlipped ? 'rotateY(180deg)' : null,
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
              transition: '.5s',
            }}>
            <Pressable
              justifyContent={'space-between'}
              padding={4}
              shadow={3}
              borderRadius={'3xl'}
              width={Dimensions.get('window').width * 0.9} // All the numbers here are taken from the standard credit card measurements of 3.37 inch x 2.125 inch
              height={Dimensions.get('window').width * 0.9 * (212.5 / 337)}
              maxWidth={'350px'} // let it bloat to 2x the size max, otherwise will take up whole screen if viewed on computer
              maxHeight={'220px'}
              onPress={() => setIsCardFlipped(!isCardFlipped)}>
              <Center>
                <Center
                  backgroundColor={'rgba(255, 255, 255, 0.2)'}
                  borderRadius={'full'}
                  paddingX={3}>
                  <Text
                    fontWeight={'semibold'}
                    color={currentRewardsTier.cardFontColor}>
                    {seller.name}
                  </Text>
                </Center>
              </Center>

              <Box>
                <Box>
                  <Text color={currentRewardsTier.cardFontColor}>
                    Current {rewardsProgramme.pointName}
                  </Text>
                </Box>

                <Box>
                  <Text>
                    <Text
                      fontSize={'3xl'}
                      fontWeight={'bold'}
                      color={currentRewardsTier.cardFontColor}>
                      {rewardsMembership.currentPoints}{' '}
                    </Text>
                    <Text
                      fontSize={'lg'}
                      fontWeight={'semibold'}
                      color={currentRewardsTier.cardFontColor}>
                      {rewardsProgramme.pointName}
                    </Text>
                  </Text>
                </Box>
              </Box>

              {/* Bottom Half */}
              <Box>
                {/*  Tier Name */}
                <Box>
                  <Text
                    fontWeight={'bold'}
                    color={currentRewardsTier.cardFontColor}>
                    {currentRewardsTier.tierName}
                  </Text>
                </Box>

                {/* Progress Bar */}
                <Box
                  width={'100%'}
                  height={1}
                  borderRadius={30}
                  backgroundColor={'rgba(255, 255, 255, 0.3)'}>
                  <Box
                    height={'100%'}
                    width={
                      !nextRewardsTier
                        ? '100%'
                        : `${
                            (rewardsMembership.cumulativePoints /
                              nextRewardsTier.pointsRequired) *
                            100
                          }%`
                    }
                    backgroundColor={currentRewardsTier.cardFontColor}
                    borderRadius={30}
                  />
                </Box>
                <Box>
                  <Text
                    variant={'subText'}
                    color={currentRewardsTier.cardFontColor}>
                    {!nextRewardsTier
                      ? 'You are at the highest tier'
                      : `${
                          nextRewardsTier.pointsRequired -
                          rewardsMembership.cumulativePoints
                        } more ${rewardsProgramme.pointName} to the next tier`}
                  </Text>
                </Box>
              </Box>
            </Pressable>
          </ImageBackground>

          <ImageBackground
            source={{
              uri: currentRewardsTier.cardBackgroundImage?.url,
            }}
            imageStyle={{ borderRadius: 16 }}
            resizeMode={'cover'}
            style={{
              borderRadius: 16,
              backgroundColor:
                currentRewardsTier?.cardBackgroundColor ?? undefined,
              position: 'absolute',
              top: 0,
              transform: isCardFlipped ? undefined : 'rotateY(180deg)',
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
              transition: '.5s',
            }}>
            <Pressable
              justifyContent={'space-between'}
              padding={4}
              shadow={3}
              borderRadius={'3xl'}
              width={Dimensions.get('window').width * 0.9} // All the numbers here are taken from the standard credit card measurements of 3.37 inch x 2.125 inch
              height={Dimensions.get('window').width * 0.9 * (212.5 / 337)}
              maxWidth={'350px'} // let it bloat to 2x the size max, otherwise will take up whole screen if viewed on computer
              maxHeight={'220px'}
              onPress={() => setIsCardFlipped(!isCardFlipped)}>
              <Box>
                {rewardsProgramme.rewardsTiers.map((tier, index) => {
                  return (
                    <Box
                      style={{ marginTop: index === 0 ? 0 : 5 }}
                      key={tier.id}>
                      <Box>
                        <Text color={currentRewardsTier.cardFontColor}>
                          {tier.tierName}
                        </Text>
                      </Box>
                      {/* TODO: Cater for different kinds of campaigns, what if don't have per dollar spent campaign? */}
                      <Box>
                        <Text
                          color={currentRewardsTier.cardFontColor}
                          variant={'subText'}>
                          Earn {tier.pointMultiplier}x{' '}
                          {rewardsProgramme?.pointName}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Pressable>
          </ImageBackground>
          <Text variant={'subText'} marginTop={2}>
            Tap card to view tier benefits
          </Text>
        </Center>
        <Box>
          {rewardsProgramme.isPointsExpiryEnabled === true &&
            rewardsProgramme.pointsExpiryWindowMonths !== null &&
            rewardsMembership.currentPoints > 0 && (
              <Center>
                <Text fontSize={'s'} fontWeight={'semibold'}>
                  {rewardsMembership.currentPoints} {rewardsProgramme.pointName}{' '}
                  will expire by{' '}
                  {DateTime.fromISO(rewardsMembership.lastActivityDatetime)
                    .plus({ months: rewardsProgramme.pointsExpiryWindowMonths })
                    .toFormat('MMMM dd, yyyy')}
                </Text>
                <Text fontSize={'xs'} fontWeight={'thin'}>
                  Make any transaction before that date to keep your{' '}
                  {rewardsProgramme.pointName}.
                </Text>
              </Center>
            )}
        </Box>
        {/* Rewards */}
        <Box marginTop={8}>
          <HStack alignItems={'center'} marginBottom={Spacing.XXS}>
            <AiFillGift size={25} />
            <Text variant={'subHeader'}> Rewards</Text>
          </HStack>

          {/* Toggle between Your Rewards and Shop For Rewards */}
          {viewingOwnedOrUnownedRewards === 'owned' && (
            <>
              <HStack
                backgroundColor={'background.0'}
                borderRadius={'full'}
                marginBottom={Spacing.XXS}
                justifyContent={'space-evenly'}>
                <Box
                  backgroundColor={Colors.BLUE_500}
                  borderRadius={'full'}
                  flex={1}
                  margin={1}
                  key={'Your Rewards Button'}>
                  <Text
                    fontWeight={'semibold'}
                    fontSize={FontSize.SM}
                    color={Colors.GRAY_50}
                    textAlign={'center'}
                    paddingY={Spacing.XXS}>
                    Your Rewards
                  </Text>
                </Box>
                <Text
                  key={'Shop For Rewards Button'}
                  margin={1}
                  fontWeight={'semibold'}
                  fontSize={FontSize.SM}
                  flex={1}
                  textAlign={'center'}
                  paddingY={Spacing.XXS}
                  onPress={() => setViewingOwnedOrUnownedRewards('unowned')}>
                  Shop For Rewards
                </Text>
              </HStack>
              {issuedRewards.length !== 0 ? (
                _(issuedRewards)
                  .orderBy((issuedReward) => issuedReward.issuedAt, ['asc'])
                  .value()
                  .map((issuedReward: Rewards.IssuedReward, index: number) => {
                    return (
                      <Box key={issuedReward.id + '_mainBox'}>
                        <Pressable
                          key={issuedReward.id + '_rewardCard'}
                          backgroundColor={'background.0'}
                          borderRadius={'lg'}
                          padding={'8'}
                          marginTop={'8'}
                          onPress={() =>
                            setIssuedRewardsLocationsToShow([
                              ...issuedRewardsLocationsToShow.slice(0, index),
                              !issuedRewardsLocationsToShow[index],
                              ...issuedRewardsLocationsToShow.slice(index + 1),
                            ])
                          }>
                          <Text variant={'subHeader'}>
                            {issuedReward.listingSnapshot.name}
                            {issuedReward.listingSnapshot.rewardsListingType ===
                              Rewards.ListingType.ITEM && (
                              <Text variant={'subHeader'}>
                                {' '}
                                {issuedReward.listingSnapshot.item
                                  .itemVariationName === 'Default'
                                  ? ''
                                  : `(${issuedReward.listingSnapshot.item.itemVariationName})`}
                              </Text>
                            )}
                          </Text>
                          <Text>
                            <Text color={'primary.500'}>
                              {issuedReward.listingSnapshot.description}
                            </Text>
                          </Text>
                          {!!issuedReward.expiresAt && (
                            <Text variant={'subText'}>
                              Expires on{' '}
                              {DateTime.fromISO(
                                issuedReward.expiresAt,
                              ).toLocaleString(DateTime.DATE_MED)}
                            </Text>
                          )}
                        </Pressable>
                        <List
                          key={issuedReward.id + '_locations'}
                          backgroundColor={'background.0'}
                          borderRadius={'lg'}
                          borderWidth={0}
                          marginTop={3}
                          display={
                            issuedRewardsLocationsToShow[index]
                              ? 'block'
                              : 'none'
                          }
                          children={
                            <Box paddingX={'8'}>
                              {issuedReward.listingSnapshot
                                .presentAtAllLocations ? (
                                <Text variant={'subText'}>
                                  Claimable at all locations
                                </Text>
                              ) : (
                                // TODO: Do up and use expansion IssuedReward.listingSnapshot.sellerLocationIds to display name
                                <Text variant={'subText'}>
                                  Claimable at{' '}
                                  {
                                    issuedReward.listingSnapshot
                                      .sellerLocationIds.length
                                  }{' '}
                                  locations
                                </Text>

                                // issuedRewardListing?.sellerLocations.map(
                                //   (location: Sellers.Location) => (
                                //     <Text variant={'subText'}>
                                //       {location.name}
                                //     </Text>
                                //   ),
                                // )
                              )}
                            </Box>
                          }
                        />
                      </Box>
                    );
                  })
              ) : (
                <Center
                  backgroundColor={'background.0'}
                  borderRadius={'2xl'}
                  padding={'4'}>
                  <Text>
                    You currently have no rewards, check out the Shop For
                    Rewards tab!
                  </Text>
                  <Lottie
                    onClick={() => setViewingOwnedOrUnownedRewards('unowned')}
                    animationData={LottieEmptyIssuedRewards}
                    loop={true}
                    autoplay={true}
                  />
                </Center>
              )}
            </>
          )}

          {viewingOwnedOrUnownedRewards === 'unowned' && (
            <Box>
              <HStack
                backgroundColor={'background.0'}
                borderRadius={'full'}
                marginBottom={Spacing.XXS}
                justifyContent={'space-evenly'}>
                <Text
                  fontWeight={'semibold'}
                  fontSize={FontSize.SM}
                  textAlign={'center'}
                  paddingY={Spacing.XXS}
                  flex={1}
                  margin={1}
                  key={'Your Rewards Button'}
                  onPress={() => setViewingOwnedOrUnownedRewards('owned')}>
                  Your Rewards
                </Text>

                <Box
                  backgroundColor={Colors.BLUE_500}
                  borderRadius={'full'}
                  flex={1}
                  margin={1}
                  key={'Shop For Rewards Button'}>
                  <Text
                    fontWeight={'semibold'}
                    fontSize={FontSize.SM}
                    textAlign={'center'}
                    paddingY={Spacing.XXS}
                    color={Colors.GRAY_50}>
                    Shop For Rewards
                  </Text>
                </Box>
              </HStack>
              {/* List of Rewards */}
              {/* TODO: ADD locations available for each reward */}
              {rewardsListings.length !== 0 ? (
                _.sortBy(
                  rewardsListings,
                  (rewardsListing) => rewardsListing.pointsRequired,
                ).map(
                  (rewardsListing: Rewards.RewardsListing, index: number) => {
                    return (
                      <Pressable
                        key={rewardsListing.id}
                        onPress={() =>
                          setConfirmClaimIndex([
                            ...confirmClaimIndex.slice(0, index),
                            !confirmClaimIndex[index],
                            ...confirmClaimIndex.slice(index + 1),
                          ])
                        }
                        backgroundColor={
                          confirmClaimIndex[index]
                            ? Colors.BLUE_100
                            : 'background.0'
                        }
                        borderRadius={'lg'}
                        padding={'8'}
                        marginTop={'8'}
                        disabled={
                          rewardsMembership.currentPoints <
                          rewardsListing.pointsRequired
                        }
                        opacity={
                          rewardsMembership.currentPoints >=
                          rewardsListing.pointsRequired
                            ? '100'
                            : '50'
                        }>
                        <HStack
                          justifyContent={'space-between'}
                          alignItems={'center'}>
                          <Box flex={1}>
                            <Text variant={'subHeader'}>
                              {rewardsListing.name}
                              {rewardsListing.rewardsListingType ===
                                Rewards.ListingType.ITEM && (
                                <Text variant={'subHeader'} marginLeft={1}>
                                  {rewardsListing.item.itemVariationName ===
                                  'Default'
                                    ? ''
                                    : `(${rewardsListing.item.itemVariationName})`}
                                </Text>
                              )}
                            </Text>
                            {!!rewardsListing.description && (
                              <Text>{rewardsListing.description}</Text>
                            )}
                            <HStack>
                              <Text
                                color={'primary.500'}
                                fontWeight={'semibold'}>
                                {rewardsListing.pointsRequired}{' '}
                              </Text>
                              <Text>{rewardsProgramme?.pointName}</Text>
                            </HStack>
                          </Box>
                          <Box
                            display={
                              confirmClaimIndex[index] ? 'block' : 'none'
                            }>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <Button
                              size={'lg'}
                              borderRadius={'full'}
                              paddingY={'2'}
                              alignItems={'center'}
                              isLoading={confirmClaimIndex[index] === 'loading'}
                              isDisabled={
                                rewardsMembership.currentPoints <
                                rewardsListing.pointsRequired
                              }
                              onPress={() =>
                                handleClaimReward(rewardsListing, index)
                              }>
                              Claim
                            </Button>
                          </Box>
                        </HStack>
                      </Pressable>
                    );
                  },
                )
              ) : (
                <Center>
                  <Text>Stay tuned for exciting rewards! 😊</Text>
                </Center>
              )}
            </Box>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default RewardsPage;
