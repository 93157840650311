import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Center,
  CircleIcon,
  Divider,
  HStack,
  Pressable,
  Spinner,
  Switch,
  Text,
} from 'native-base';
import React from 'react';

import { Buyers, Customers } from '@waffle/common/src/models';

import WaffleLoaderComponent from '../../../components/WaffleLoaderComponent';
import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';
import { useCustomerQuery } from '../../../hooks/queries/useCustomer';
import { useSubdomainSeller } from '../../../hooks/queries/useSubdomainSeller';
import buyersApiClient from '../../../utils/ApiService';

const DataAndPrivacyPage = () => {
  const queryClient = useQueryClient();
  const { data: seller, isError: isSellerError } = useSubdomainSeller();
  const { data: buyerUser, isError: isBuyerUserError } = useBuyerUser();
  const { data: customer, isError: isCustomerError } = useCustomerQuery();

  const {
    mutate: setPreferredCommunicationsChannel,
    isPending: isSetPreferredCommunicationsChannelPending,
  } = useMutation({
    mutationFn: async (communicationsChannel: Buyers.CommunicationsChannel) => {
      if (!buyerUser) {
        throw new Error('No BuyerUser found');
      }

      await buyersApiClient.request({
        method: 'PUT',
        url: `/buyer_users/${buyerUser?.id}`,
        data: {
          buyerUser: {
            preferredCommunicationsChannel: communicationsChannel,
          },
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/buyer_users'] });
    },
  });

  const {
    mutate: toggleCustomerMarketingConsent,
    isPending: isToggleCustomerMarketingConsentPending,
  } = useMutation({
    mutationFn: async ({ customer }: { customer: Customers.Customer }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: `/customers/${customer.id}/set_marketing_consent`,
        data: {
          didConsentMarketing: !customer.didConsentMarketing,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/customers'] });
    },
  });

  const {
    mutate: toggleCustomerShareProfileConsent,
    isPending: isToggleCustomerShareProfileConsentPending,
  } = useMutation({
    mutationFn: async ({ customer }: { customer: Customers.Customer }) => {
      await buyersApiClient.request({
        method: 'POST',
        url: `/customers/${customer.id}/set_share_profile_consent`,
        data: {
          didConsentShareProfile: !customer.didConsentShareProfile,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/customers'] });
    },
  });

  if (!seller || !buyerUser || !customer) {
    return <WaffleLoaderComponent />;
  }

  if (isSellerError || isBuyerUserError || isCustomerError) {
    return <Text>Encountered an error, please refresh the page!</Text>;
  }

  return (
    <Box alignItems={'center'}>
      <Box width={'100%'} padding={4} maxWidth={'600px'}>
        <Text variant={'header'} marginBottom={4}>
          Data & Privacy
        </Text>

        <Box marginBottom={'8'}>
          <Box
            backgroundColor={'background.0'}
            padding={'4'}
            borderRadius={'lg'}>
            <Text variant={'subHeader'}>Preferred communications channel</Text>
            <Text variant={'subText'}>
              Marketing communications and notifications will be sent via your
              preferred channel, where possible.
            </Text>
            <Divider marginY={2} />

            <Pressable
              flexDirection={'row'}
              alignItems={'center'}
              padding={2}
              borderRadius={'md'}
              _hover={{ backgroundColor: 'gray.100' }}
              onPress={() =>
                setPreferredCommunicationsChannel(
                  Buyers.CommunicationsChannel.SMS,
                )
              }>
              <Center
                size={5}
                borderRadius={'full'}
                borderWidth={1}
                borderColor={'background.300'}
                marginRight={2}>
                {buyerUser.preferredCommunicationsChannel ===
                  Buyers.CommunicationsChannel.SMS && (
                  <CircleIcon size={3} color={'primary.600'} />
                )}
              </Center>
              <Text>SMS</Text>
            </Pressable>
            <Pressable
              flexDirection={'row'}
              alignItems={'center'}
              padding={2}
              borderRadius={'md'}
              _hover={{ backgroundColor: 'gray.100' }}
              onPress={() =>
                setPreferredCommunicationsChannel(
                  Buyers.CommunicationsChannel.EMAIL,
                )
              }>
              <Center
                size={5}
                borderRadius={'full'}
                borderWidth={1}
                borderColor={'background.300'}
                marginRight={2}>
                {buyerUser.preferredCommunicationsChannel ===
                  Buyers.CommunicationsChannel.EMAIL && (
                  <CircleIcon size={3} color={'primary.600'} />
                )}
              </Center>
              <Text>Email</Text>
            </Pressable>
          </Box>
        </Box>

        <Box marginBottom={'8'}>
          <Box
            backgroundColor={'background.0'}
            padding={'4'}
            borderRadius={'lg'}>
            <Text variant={'subHeader'}>Data Sharing</Text>
            <Divider marginY={2} />
            <HStack justifyContent={'space-between'} marginBottom={4}>
              <Box flex={1}>
                <Text variant={'label'}>Share Required Data</Text>
                <Text variant={'subText'}>
                  Required data such as your mobile number and email address
                  will always be shared with {seller.name} so that they can
                  provide you with better services. You will never receive
                  unsolicited marketing communications.
                </Text>
              </Box>

              <Box minWidth={'64px'} alignItems={'flex-end'}>
                {/*This can never be un-toggled because Buyer will always have to share their email address and mobile number to continue using Waffle's services*/}
                <Switch isDisabled={true} isChecked={true} />
              </Box>
            </HStack>

            <HStack justifyContent={'space-between'}>
              <Box flex={1}>
                <Text variant={'label'}>Share Additional Data</Text>
                <Text variant={'subText'}>
                  {`Additional data, such as your name and birthday, helps ${seller.name} provide you with more personalised services and rewards.`}
                </Text>
              </Box>
              <Box minWidth={'64px'} alignItems={'flex-end'}>
                {isToggleCustomerShareProfileConsentPending ? (
                  <Spinner />
                ) : (
                  <Switch
                    isChecked={!!customer.didConsentShareProfile}
                    onToggle={() => {
                      toggleCustomerShareProfileConsent({ customer: customer });
                    }}
                  />
                )}
              </Box>
            </HStack>
          </Box>
        </Box>

        <Box marginBottom={'8'}>
          <Box
            backgroundColor={'background.0'}
            padding={'4'}
            borderRadius={'lg'}>
            <Text variant={'subHeader'}>Consent to marketing</Text>
            <Text variant={'subText'}>
              {seller.name} will never send you unsolicited marketing
              communications, unless you explicitly allow it.
            </Text>
            <Divider marginY={2} />
            <HStack justifyContent={'space-between'}>
              <Text>
                Receive marketing communications from{' '}
                <Text fontWeight={'semibold'}>{seller.name}</Text>
              </Text>

              <Box minWidth={'64px'} alignItems={'flex-end'}>
                {isToggleCustomerMarketingConsentPending ? (
                  <Spinner />
                ) : (
                  <Switch
                    isChecked={customer.didConsentMarketing}
                    onToggle={() => {
                      toggleCustomerMarketingConsent({ customer: customer });
                    }}
                  />
                )}
              </Box>
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DataAndPrivacyPage;
