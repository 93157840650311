import { DateTime } from 'luxon';
import { Box, Divider, Spinner, Text } from 'native-base';
import React from 'react';

import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';
import { useCustomerQuery } from '../../../hooks/queries/useCustomer';
import { useSubdomainSeller } from '../../../hooks/queries/useSubdomainSeller';

const PrivacyPolicyPage = () => {
  const { data: seller, isError: isSellerError } = useSubdomainSeller();
  const { data: buyerUser, isError: isBuyerUserError } = useBuyerUser();

  const { data: customer, isError: isCustomerError } = useCustomerQuery();

  if (!seller || !customer || !buyerUser) {
    return <Spinner />;
  }

  if (isSellerError || isCustomerError || isBuyerUserError) {
    return <Text>Encountered an error, please refresh the page!</Text>;
  }

  return (
    <Box alignItems={'center'}>
      <Box width={'100%'} padding={4} maxWidth={'600px'}>
        <Text variant={'header'}>Privacy Policy</Text>
        <Text>Important information about how we may use your data</Text>
        <Divider marginY={4} />
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingRight={8}
          marginY={4}>
          <Box>
            <Text variant={'label'}>Waffle</Text>
            <Text variant={'subText'}>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  'https://www.wafflepos.com/legal/waffle-buyer-privacy-policy'
                }
                style={{ color: 'inherit' }}>
                Privacy Policy
              </a>
            </Text>
          </Box>
          <Box>
            {!!buyerUser.acceptedWafflePrivacyPolicyAt ? (
              <>
                <Text variant={'subText'}>Accepted on</Text>
                <Text>
                  {DateTime.fromISO(
                    buyerUser.acceptedWafflePrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              </>
            ) : null}
          </Box>
        </Box>
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingRight={8}
          marginY={4}>
          <Box>
            <Text variant={'label'}>{seller.name}</Text>
            <Text variant={'subText'}>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  seller.latestSellerPrivacyPolicy?.file?.url ??
                  `https://www.wafflepos.com/legal/seller-buyer-privacy-policy?seller=${seller.slug}`
                }
                style={{ color: 'inherit' }}>
                Privacy Policy
              </a>
            </Text>
          </Box>
          <Box>
            {!!customer.acceptedSellerPrivacyPolicyAt ? (
              <>
                <Text variant={'subText'}>Accepted on</Text>
                <Text>
                  {DateTime.fromISO(
                    customer.acceptedSellerPrivacyPolicyAt,
                  ).toLocaleString(DateTime.DATE_MED)}
                </Text>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyPage;
