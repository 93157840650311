import { useQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import { useRewardsMembership } from './useRewardsMembership';
import { useSubdomainSeller } from './useSubdomainSeller';

export const useIssuedRewards = () => {
  const { data: seller } = useSubdomainSeller();
  const { data: rewardsMembership } = useRewardsMembership();
  return useQuery<Rewards.IssuedReward[]>({
    queryKey: ['/issued_rewards', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'GET',
          url: `/issued_rewards`,
          params: {
            sellerId: seller?.id,
            states: [Rewards.IssuedRewardState.ISSUED],
          },
        })
      ).issuedRewards;
    },
    enabled: !!seller && !!rewardsMembership,
  });
};
