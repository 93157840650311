export { Colors, Position, FontWeight } from '@waffle/common/src/styles/styles';

export const Spacing = {
  XXS: 4,
  XS: 8,
  SM: 12,
  MD: 16,
  LG: 24,
  XL: 32,
  XXL: 48,
};

export const FontSize = {
  XS: 12,
  SM: 18,
  MD: 24,
  LG: 32,
  XL: 48,
  XXL: 64,
};

export const LineHeight = {
  XS: 12,
  SM: 14,
  MD: 16,
  LG: 18,
  XL: 20,
  XXL: 24,
};

export const BorderRadius = {
  NONE: 0,
  XS: 2,
  SM: 4,
  MD: 8,
  LG: 16,
  XL: 24,
  XXL: 48,
  FULL: 9999,
};

export const ZIndex = {
  TOP_NAV: 100,
  PAGE_STICKY_HEADER: 10,
  DROPDOWN: 9,
};
