import _ from 'lodash';
import { DateTime } from 'luxon';
import { Box, Divider, HStack, Text } from 'native-base';
import React, { useMemo } from 'react';

import { Rewards } from '@waffle/common/src/models';

import WaffleLoaderComponent from '../../../components/WaffleLoaderComponent';
import { useRewardsMembership } from '../../../hooks/queries/useRewardsMembership';
import { useRewardsPointTransactions } from '../../../hooks/queries/useRewardsPointTransactions';

const TransactionsPointsClaimedPage = () => {
  const {
    data: rewardsPointTransactions,
    isError: isRewardsPointTransactionsError,
  } = useRewardsPointTransactions();
  const { data: rewardsMembership, isError: isRewardsMembershipError } =
    useRewardsMembership();

  // Sort and filter out the CLAIMED rewardsPointTransactions
  // TODO: Filter on server side
  const rewardsPointTransactionsClaimed:
    | Rewards.PointTransaction[]
    | undefined = useMemo(() => {
    if (!rewardsPointTransactions) {
      return undefined;
    }
    return rewardsPointTransactions
      .filter(
        (rewardsPointTransaction) => rewardsPointTransaction.pointsAwarded < 0,
      )
      .sort((rewardsPointTransaction1, rewardsPointTransaction2) =>
        rewardsPointTransaction1.createdAt < rewardsPointTransaction2.createdAt
          ? 1
          : -1,
      );
  }, [rewardsPointTransactions]);

  // group the claimed rewardsPointTransactions by their date
  const rewardsPointTransactionsByDate: {
    [date: string]: Rewards.PointTransaction[];
  } = _.groupBy(rewardsPointTransactionsClaimed, (rpt) =>
    DateTime.fromISO(rpt.createdAt).toISODate(),
  );

  if (isRewardsMembershipError || isRewardsPointTransactionsError) {
    return <Text>Encountered an error, please try again later!</Text>;
  }

  if (!rewardsPointTransactions) {
    return <WaffleLoaderComponent />;
  }

  if (!rewardsMembership) {
    return <Text>You are not a rewards member for this seller.</Text>;
  }

  return (
    <Box width={'container'}>
      {Object.entries(rewardsPointTransactionsByDate).map(
        ([date, rewardsPointTransactions]: [
          string,
          Rewards.PointTransaction[],
        ]) => (
          <Box key={date}>
            <Box background={'background.200'}>
              <Text padding={'2'}>
                {`${DateTime.fromISO(date).weekdayLong}, ` +
                  `${DateTime.fromISO(date).toFormat('dd LLLL yyyy')}`}
              </Text>
            </Box>
            <Box>
              {rewardsPointTransactions.map((rewardsPointTransaction) => (
                <Box key={rewardsPointTransaction.id}>
                  <HStack justifyContent={'space-between'}>
                    <Text padding={'3'}>
                      {DateTime.fromISO(
                        rewardsPointTransaction.createdAt,
                      ).toFormat('hh:mm a')}
                      {rewardsPointTransaction.type ===
                        Rewards.PointTransactionType.EXPIRY &&
                        '  (Points Expired)'}
                    </Text>
                    <Text
                      color={'primary.600'}
                      fontWeight={'semibold'}
                      padding={'3'}>
                      {rewardsPointTransaction.pointsAwarded}
                    </Text>
                  </HStack>
                  <Divider thickness={'2'} />
                </Box>
              ))}
            </Box>
          </Box>
        ),
      )}
    </Box>
  );
};

export default TransactionsPointsClaimedPage;
