import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Center,
  CheckIcon,
  Checkbox,
  CloseIcon,
  Divider,
  HStack,
  Image,
  Link,
  Modal,
  Popover,
  Pressable,
  Text,
} from 'native-base';
import React, { useState } from 'react';

import { Customers } from '@waffle/common/src/models';

import WaffleLogoWithWord from '../../../assets/logo/waffle-logo-words-small.png';
import WaffleLoaderComponent from '../../../components/WaffleLoaderComponent';
import { useBuyerUser } from '../../../hooks/queries/useBuyerUser';
import { useSubdomainSeller } from '../../../hooks/queries/useSubdomainSeller';
import buyersApiClient from '../../../utils/ApiService';

const PrivacyConsentPage = () => {
  const queryClient = useQueryClient();
  const { data: buyerUser, isError: isBuyerUserError } = useBuyerUser();
  const { data: seller } = useSubdomainSeller();

  const [showModal, setShowModal] = useState(false);
  const [didConsentShareProfile, setDidConsentShareProfile] = useState(true);
  const [didConsentMarketing, setDidConsentMarketing] = useState(true);

  const { mutate: setCustomerConsent, isPending: isSetCustomerConsentPending } =
    useMutation({
      mutationFn: async ({
        sellerId,
        didConsentShareProfile,
        didConsentMarketing,
      }: {
        sellerId: string;
        didConsentShareProfile: boolean;
        didConsentMarketing: boolean;
      }) => {
        let customer: Customers.Customer | undefined = undefined;

        // 1. Retrieve any existing Customer for this Buyer-Seller combination
        const resCustomers: Customers.Customer[] = (
          await buyersApiClient.request({
            method: 'GET',
            url: '/customers',
            params: { sellerId: seller?.id },
            validateStatus: (status) => {
              if (status >= 400 && status !== 404) {
                return false;
              }
              return true;
            },
          })
        ).customers;

        if (resCustomers.length === 1) {
          customer = resCustomers[0];
        }

        if (!customer) {
          customer = (
            await buyersApiClient.request({
              method: 'POST',
              url: '/customers',
              data: {
                sellerId: sellerId,
              },
            })
          ).customer;
        }

        if (!customer) {
          throw new Error('Failed to create Customer');
        }

        // Set Privacy Policy consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/accept_privacy_policy`,
        });

        // Set share profile consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/set_share_profile_consent`,
          data: {
            didConsentShareProfile: didConsentShareProfile,
          },
        });

        // Set marketing consent
        await buyersApiClient.request({
          method: 'POST',
          url: `/customers/${customer.id}/set_marketing_consent`,
          data: {
            didConsentMarketing: didConsentMarketing,
          },
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['/customers'] });
      },
    });

  if (isBuyerUserError) {
    return <Text>Encountered an error, please refresh the page!</Text>;
  }

  if (!seller || !buyerUser) {
    return <WaffleLoaderComponent />;
  }

  if (!buyerUser.mobileNumber) {
    return (
      <Center flex={1} backgroundColor={'background.100'}>
        <Text>Invalid Buyer! Missing Phone Number. Please Contact Us.</Text>
      </Center>
    );
  }

  return (
    <>
      <Center flex={1} backgroundColor={'background.100'}>
        <Center
          flex={1}
          justifyContent={'space-between'}
          minHeight={'100%'}
          maxWidth={480}
          width={'full'}
          padding={2}>
          <Box width={'100%'}>
            <Center
              width={'full'}
              borderRadius={'3xl'}
              backgroundColor={'surface.0'}
              padding={6}
              marginBottom={4}>
              {!!seller.logoImage && (
                <Box
                  height={'80px'}
                  width={'80px'}
                  shadow={'5'}
                  borderRadius={'md'}
                  marginBottom={4}>
                  <Image
                    resizeMode={'contain'}
                    height={'100%'}
                    width={'100%'}
                    src={seller.logoImage.thumbnailUrl}
                    alt={'Seller Logo'}
                  />
                </Box>
              )}
              <Text variant={'subHeader'}>{seller.name}</Text>
            </Center>

            <Box
              width={'full'}
              borderRadius={'3xl'}
              backgroundColor={'surface.0'}
              padding={6}
              textAlign={'left'}
              marginBottom={4}>
              <Text variant={'subHeader'}>Data & Privacy</Text>

              <Divider marginY={2} />

              <HStack alignItems={'center'} justifyContent={'space-between'}>
                <HStack alignItems={'center'}>
                  <Text variant={'label'}>Share Required Data</Text>
                  <Popover
                    trigger={(triggerProps) => {
                      return (
                        <Pressable {...triggerProps} marginLeft={2}>
                          <Text variant={'subText'} underline={true}>
                            (?)
                          </Text>
                        </Pressable>
                      );
                    }}>
                    <Popover.Content>
                      <Popover.Body maxWidth={'400px'}>
                        <Text variant={'subText'}>
                          Required data such as your mobile number and email
                          address will always be shared with {seller.name} so
                          that they can provide you with better services.
                        </Text>
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </HStack>
                <Checkbox
                  value={'shareRequiredData'}
                  isChecked={true}
                  isDisabled={true}
                />
              </HStack>
              {!!buyerUser.mobileNumber && (
                <Box marginTop={2}>
                  <Text variant={'subText'}>Mobile Number</Text>
                  <Text>{buyerUser.mobileNumber}</Text>
                </Box>
              )}
              {!!buyerUser.emailAddress && (
                <Box marginTop={2}>
                  <Text variant={'subText'}>Email Address</Text>
                  <Text>{buyerUser.emailAddress}</Text>
                </Box>
              )}

              <Divider marginY={2} />

              <HStack alignItems={'center'} justifyContent={'space-between'}>
                <HStack alignItems={'center'}>
                  <Text variant={'label'}>Share Additional Data</Text>
                  <Popover
                    trigger={(triggerProps) => {
                      return (
                        <Pressable {...triggerProps} marginLeft={2}>
                          <Text variant={'subText'} underline={true}>
                            (?)
                          </Text>
                        </Pressable>
                      );
                    }}>
                    <Popover.Content>
                      <Popover.Body maxWidth={'400px'}>
                        <Text variant={'subText'}>
                          {`Additional data, such as your name and birthday, helps ${seller.name} provide you with more personalised services and rewards.`}
                        </Text>
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </HStack>
                <Checkbox
                  value={'didConsentShareProfile'}
                  isChecked={didConsentShareProfile}
                  onChange={() =>
                    setDidConsentShareProfile(!didConsentShareProfile)
                  }
                />
              </HStack>

              <Box marginTop={2}>
                {!buyerUser.dateOfBirth &&
                !buyerUser.familyName &&
                !buyerUser.givenName ? (
                  <Text variant={'subText'}>
                    Your profile is currently empty but details that you add to
                    your profile will be shared with {seller.name}
                  </Text>
                ) : (
                  <>
                    {!!buyerUser.givenName && (
                      <Box marginTop={2}>
                        <Text variant={'subText'}>Given Name</Text>
                        <Text>{buyerUser.givenName}</Text>
                      </Box>
                    )}
                    {!!buyerUser.familyName && (
                      <Box marginTop={2}>
                        <Text variant={'subText'}>Family Name</Text>
                        <Text>{buyerUser.familyName}</Text>
                      </Box>
                    )}
                    {!!buyerUser.dateOfBirth && (
                      <Box marginTop={2}>
                        <Text variant={'subText'}>Date of Birth</Text>
                        <Text>{buyerUser.dateOfBirth}</Text>
                      </Box>
                    )}
                    <Text variant={'subText'} marginY={4}>
                      Profile last updated:{' '}
                      {DateTime.fromISO(buyerUser.updatedAt).toLocaleString(
                        DateTime.DATE_MED,
                      )}
                    </Text>
                  </>
                )}
              </Box>
            </Box>

            <Box marginTop={4} marginBottom={8} width={'100%'} padding={6}>
              <HStack marginBottom={2}>
                <Checkbox
                  value={'didConsentMarketing'}
                  isChecked={didConsentMarketing}
                  onChange={() => setDidConsentMarketing(!didConsentMarketing)}
                />
                <Text flex={1} variant={'subText'} marginLeft={2}>
                  I want to recieve offers and updates from {seller.name}.
                </Text>
              </HStack>

              <Button
                width={'100%'}
                onPress={() => {
                  if (!didConsentShareProfile) {
                    setShowModal(true);
                    return;
                  }

                  setCustomerConsent({
                    sellerId: seller.id,
                    didConsentShareProfile: didConsentShareProfile,
                    didConsentMarketing: didConsentMarketing,
                  });
                }}
                isLoading={isSetCustomerConsentPending}
                size={'lg'}>
                Continue
              </Button>

              <Text variant={'subText'} marginTop={2} textAlign={'center'}>
                {`By continuing, you agree to the `}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    seller.latestSellerPrivacyPolicy?.file?.url ??
                    `https://www.wafflepos.com/legal/seller-buyer-privacy-policy?seller=${seller.slug}`
                  }
                  style={{
                    color: 'inherit',
                  }}>{`${seller.name} Privacy Policy`}</a>
                {`.`}
              </Text>
            </Box>
          </Box>

          <Box>
            <Link href={`https://www.wafflepos.com/`} isExternal>
              <Image
                source={WaffleLogoWithWord}
                alt={'Waffle Logo'}
                resizeMode={'contain'}
                height={'10'}
                width={'100'}
              />
            </Link>
          </Box>
        </Center>
      </Center>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={'lg'}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Continue without sharing additional data</Modal.Header>
          <Modal.Body>
            <Box marginBottom={4}>
              <Text>
                By continuing without sharing optional data, you can still
              </Text>
              <HStack alignItems={'baseline'} marginTop={2}>
                <CheckIcon size={3} color={'background.400'} />
                <Text flex={1} marginLeft={4}>
                  Collect points and redeem rewards when you visit
                </Text>
              </HStack>
            </Box>

            <Box marginBottom={4}>
              <Text>
                However, you may <Text bold>miss out</Text> on the following
                benefits
              </Text>
              <HStack alignItems={'baseline'} marginTop={2}>
                <CloseIcon size={3} color={'background.400'} />
                <Text flex={1} marginLeft={4}>
                  Access to unique deals and rewards given to members, eg.
                  rewards on your birthday.
                </Text>
              </HStack>
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button
              flex={1}
              onPress={() => {
                setCustomerConsent({
                  sellerId: seller.id,
                  didConsentShareProfile: didConsentShareProfile,
                  didConsentMarketing: didConsentMarketing,
                });
              }}
              isLoading={isSetCustomerConsentPending}
              backgroundColor={'red.500'}>
              Continue Without Sharing
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PrivacyConsentPage;
