import { useQuery } from '@tanstack/react-query';

import { Rewards } from '@waffle/common/src/models';

import buyersApiClient from '../../utils/ApiService';
import { useSubdomainSeller } from './useSubdomainSeller';

export const useRewardsMembership = () => {
  const { data: seller } = useSubdomainSeller();
  return useQuery<Rewards.RewardsMembership>({
    queryKey: [`/rewards_memberships`, '/assert', { sellerId: seller?.id }],
    queryFn: async () => {
      return (
        await buyersApiClient.request({
          method: 'POST',
          url: `/rewards_memberships/assert`,
          params: {
            sellerId: seller?.id,
            expand: [
              'rewardsMembership.customer.customerSets',
              'rewardsMembership.rewardsTier.cardBackgroundImage',
            ],
          },
        })
      ).rewardsMembership;
    },
    enabled: !!seller,
  });
};
