/**
 * https://github.com/lodash/lodash/issues/4400
 *
 * Case
 * 1. If function was called outside the timeout period:
 *    - We call the underlying callback function
 *
 * 2. If function is called within the timeout period:
 *    2a. If there is already a pending promise that is resolved, we return the resolved promise
 *    2b. If there is a pending promise that is not resolved, we return this promise
 *
 * <---------------------Timeout period------------------------------>
 * 1                         2b                           2a                            1 (Case no.)
 * |-------------------------|----------------------------|-----------------------------| (`|` represents function call)
 * Promise<pending res1>   Promise<pending res1>      Promise<res 1>             Promise<pending res2>    (Return results)
 *
 * Implementation based off https://github.com/sindresorhus/p-debounce/blob/main/index.js
 */
export const debounceAsync = <
  T,
  Callback extends (...args: any[]) => Promise<T>,
>(
  callback: Callback,
  wait: number,
): ((...args: Parameters<Callback>) => Promise<T>) => {
  if (!Number.isFinite(wait)) {
    throw new TypeError('Expected `wait` to be a finite number');
  }

  let prevResult;
  let timeout;
  let resolveList = [];

  const handleFn = async (...args: any[]) => {
    return new Promise((resolve) => {
      const shouldCallNow = !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;

        for (resolve of resolveList) {
          resolve(prevResult);
        }

        resolveList = [];
      }, wait);

      if (shouldCallNow) {
        prevResult = callback(...args);
        resolve(prevResult);
      } else {
        resolveList.push(resolve);
      }
    });
  };

  let currentPromise;
  return async (...args) => {
    if (currentPromise) {
      return currentPromise;
    }

    try {
      currentPromise = handleFn(...args);
      return currentPromise;
    } finally {
      currentPromise = undefined;
    }
  };
};
