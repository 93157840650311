import { atom, useAtom } from 'jotai';
import Cookies from 'js-cookie';

import EnvConfig from './EnvConfig';

/**
 * We combine localStorage with cookie storage
 * Cookie
 * - Pro: Can share between tabs and subdomains
 * - Con: Max lifetime on Safari is ~7 days
 *
 * LocalStorage
 * - Pro: Can live forever (until user decides to manually purge the cache)
 * - Con: Cannot share between tabs, cannot share between subdomains
 */
const atomWithCookieAndLocalStorage = ({
  key,
  domain,
}: {
  key: string;
  domain?: string;
}) => {
  const baseAtom = atom<string | undefined>(
    Cookies.get(key) ?? localStorage.getItem(key) ?? undefined,
  ); // Initial read from cookies
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (_get, set, nextValue: string | undefined) => {
      set(baseAtom, nextValue);
      if (!nextValue) {
        Cookies.remove(key);
        localStorage.removeItem(key);
      } else {
        Cookies.set(key, nextValue, { domain: domain }); // Write to cookies
        localStorage.setItem(key, nextValue); // Write to localStorage
      }
    },
  );
  return derivedAtom;
};
export const accessTokenAtom = atomWithCookieAndLocalStorage({
  key: `WAFFLE__${EnvConfig.CLIENT_ENV.toUpperCase()}__BUYERS__ACCESS_TOKEN`,
  domain: EnvConfig.APP_BASE_DOMAIN,
});
export const refreshTokenAtom = atomWithCookieAndLocalStorage({
  key: `WAFFLE__${EnvConfig.CLIENT_ENV.toUpperCase()}__BUYERS__REFRESH_TOKEN`,
  domain: EnvConfig.APP_BASE_DOMAIN,
});

export const useAuth = () => {
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom);

  const isAuthenticated = !!accessToken;
  return {
    isAuthenticated,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  };
};
